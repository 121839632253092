export type Employee = {
  label: string;
  value: string;
  description: string;
  availableBricks?: number;
  redeemableBricks?: number;
  totalEarntBricks?: number;
};

export type CurrentEmployee = Employee & { leaderboardPosition?: number };

export const nonFounders = [
  {
    label: "Carlos Saenz",
    value: "0xa94a057b8730405d709AaF6efe6a24D91D9f27dC",
    description: "Eng",
  },
  {
    label: "Ilken Bahcecioglu",
    value: "0x68B0500B5F3af524613859803874d768771276d6",
    description: "Eng",
  },
  {
    label: "Arieh Levy",
    value: "0x7b05590eE73b6541ADecCba75A9Cd6BB561f6781",
    description: "BizDev",
  },
  {
    label: "Carlos Bernal (Profe)",
    value: "0x69A17F04d49BAC28caDa0c6f1EB2ed4ED4787cEA",
    description: "Eng",
  },
  {
    label: "Ray Cheung",
    value: "0x2F363D46ab5df338d578203A114210D4e1b249F0",
    description: "Design",
  },
  {
    label: "Tomas Vinella",
    value: "0x9541Bf5b6DF4Fb72C910b62D7CEF0136b4171C71",
    description: "Marketing",
  },
  {
    label: "Sebastian Burford",
    value: "0x7C46dBbe8364Ad04e211FfdD7590433f3155B09F",
    description: "Marketing",
  },
  {
    label: "Dora Yilmaz",
    value: "0x84391135Bb95a367BC7109891043f1E8454da21c",
    description: "Artist Liaison",
  },
  {
    label: "Jacint Varga",
    value: "0x3A8909b52145E552ecD1E6D67498D2d324Bbc04C",
    description: "Eng",
  },
  {
    label: "Norman",
    value: "0xb7Cb6ABBF60FDC2F811BEFcEA733871DA6DE73c3",
    description: "Eng",
  },
];

const founders = [
  {
    label: "Nathan Cohen",
    value: "0x0adf28B0EC7dd6B4024602a190381C46a424e5e9",
    description: "Founder",
  },
  {
    label: "Isaac Bentata",
    value: "0x476B535CF58dbdB440fCd4e8bC21c847B70A5314",
    description: "Founder",
  },
  {
    label: "Isaac Kamlish",
    value: "0xe2155d84Db573010449d269162427D72C7Bd2157",
    description: "Founder",
  },
];

export const employeeList = nonFounders.concat(founders);

export const isNotFounder = (employee?: Employee) => {
  if (!employee) return true;
  return !founders.some(founder => founder.value === employee.value);
};
